<template>
  <div>
    <v-row style="margin-top: 13%" align="center" justify="space-around">
      <v-card elevation="2" outlined style="width: 500px" :loading="loading">
        <v-container style="text-align: center;">
          <h1>Password Reset</h1>
          <v-divider></v-divider>
        </v-container>
        <v-container style="text-align: center;">
          <p>Please enter your account username or email.</p>
        </v-container>
        <v-form ref="form" lazy-validation style="padding: 0px 30px 30px 30px" v-model="valid">
          <v-text-field
            ref="userNameOrEmail_input"
            v-model="usernameOrEmail"
            :rules="usernameOrEmailRules"
            label="Username or Email"
            v-on:keyup.enter="sendPasswordReset()"
          ></v-text-field>
          <v-text-field style="display: none;" disabled></v-text-field>

          <v-row>
            <div style="padding: 16px 10px 0px 10px; width: 100%">
              <v-btn block color="blue" class="white--text" @click="sendPasswordReset()" :disabled="!valid">
                Reset Password
              </v-btn>
            </div>
          </v-row>

          <v-row>
            <div style="padding: 8px 10px 0px 10px; width: 100%">
              <v-btn block text @click="$router.push('/login')">
                Back to Login
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    <v-container d-flex justify-center style="margin-top: 6px;">
      <v-alert
        v-model="alert.show"
        dismissible
        :color="alert.color"
        :icon="alert.icon"
        border="left"
        elevation="2"
        colored-border
        width="500px"
      >
        {{ alert.text }}
      </v-alert>
    </v-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        valid: true,
        usernameOrEmail: this.$store.getters.getForgottenUser.userNameOrEmail,
        usernameOrEmailRules: [v => !!v || "Username or Email is required"],
        showPassword: false,
        loading: false,
        alert: {
          show: false,
          text: "",
          color: "red",
          icon: "mdi-alert-circle-outline"
        },
      };
    },
    mounted: function() {
      this.$refs.userNameOrEmail_input.focus();
    },
    methods: {
      sendPasswordReset() {
        this.loading = true;
        this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/users/resetpassword`, {userNameOrEmail: this.usernameOrEmail})
          .then(result => {
            this.loading = false;
            this.alert.show = true;
            this.alert.text = result.statusText;
            this.alert.color = "green";
            this.alert.icon = "mdi-check-circle-outline";
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.alert.show = true;
            this.alert.text = err.response.statusText;
            this.alert.color = "red";
            this.alert.icon = "mdi-alert-circle-outline";
          });
      }
    },
  };
</script>
